console.log("Hello world");

document.addEventListener("DOMContentLoaded", () => {
  const screenshots = document.querySelectorAll(".screenshot");
  let activeScreenshot = 0;
  let nextScreenshot = 1;
  screenshots[activeScreenshot].classList.add("active");
  
  window.setInterval(() => {
    screenshots[activeScreenshot].classList.replace("active", "inactive");
    screenshots[nextScreenshot].classList.replace("inactive", "active");
    activeScreenshot = activeScreenshot < screenshots.length - 1 ? activeScreenshot + 1 : 0;
    nextScreenshot = nextScreenshot < screenshots.length - 1 ? nextScreenshot + 1 : 0;
  }, 3500);
});